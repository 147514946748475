import React, { useState } from 'react';
import './index.css'; // Ensure the CSS file is linked
import { OrbitProgress } from 'react-loading-indicators';
import { InlineWidget } from 'react-calendly';

function ScheduleCall({ scheduleRef }) {
    const [email, setEmail] = useState()
    const [isLoading, setIsLoading] = useState()
    const [formSuccess, setFormSuccess] = useState()
    const [formError, setFormError] = useState(false)

    const enquire = () => {
        setIsLoading(true)
        var params = {
            email: email
        }

        fetch(`https://w2xjmr0ofh.execute-api.eu-west-2.amazonaws.com/production/enquiry/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(params)
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response.data)
                if (response == 200) {
                    setFormSuccess(true)
                    setFormError(false)
                } else {
                    setFormSuccess(false)
                    setFormError(true)
                }
                setIsLoading(false)
                setEmail()
            })
            .catch((err) => {
                console.log(err.message);
                setIsLoading(false)
                setFormSuccess(false)
                setFormError(true)
            });
    }

    return (
        <section ref={scheduleRef} className="schedule-section">
            <div className="schedule-section-container">
                <h2 className="schedule-section-title">Schedule a call</h2>
                <p className="schedule-section-description">Let's go through your plans and see how we can help</p>
                <InlineWidget className="schedule-section-calendar" url="https://calendly.com/mdclab/30min" pageSettings={{
                    hideGdprBanner: true,
                    hideEventTypeDetails: false,
                }} styles={{
                    height: '800px'
                }} />
            </div>
            <div className="schedule-section-enquire">
                <p className="schedule-section-enquire-or">or</p>
                <p className="schedule-section-enquire-title">Leave your email and we'll get back to you</p>
                <div className="schedule-section-enquire-form">

                    <div className="schedule-section-enquire-form-container">
                        {
                            isLoading ? (
                                <OrbitProgress color="#888" size="small" text="" textColor="" />
                            ) : (
                                <form onSubmit={enquire}>
                                    <input type="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} required/>
                                    <button type="submit">Enquire</button>
                                </form>
                            )
                        }
                    </div>
                </div>
                <p>
                    {formSuccess && <span className={formSuccess ? "schedule-section-enquire-form-success visible" : "schedule-section-enquire-form-success"}>Done! Sending you more info now</span>}
                    {formError && <span className={formError ? "schedule-section-enquire-form-error visible" : "schedule-section-enquire-form-error"}>Something went wrong, try again</span>}
                </p>
            </div>
        </section>
    )
}

export default ScheduleCall;