import React, { useRef } from 'react';
import { motion, useIsPresent } from 'framer-motion';
import Header from '../../Components/Header';
import WhyUsSection from '../../Components/WhyUs';
import HowItWorks from '../../Components/HowItWorks';
import SocialResponsibilitySection from '../../Components/SocialResponsibility';
import OurPartnersSection from '../../Components/OurPartners';
import PricingTwo from '../../Components/Pricing';
import ScheduleCall from '../../Components/ScheduleCall';
import AboutUsSection from '../../Components/AboutUs';
import Footer from '../../Components/Footer';
import { pageVariants, pageTransition } from '../../variants';
import HRHero from './HRHero';

function HRFirmsPage() {
    const scheduleRef = useRef(null)
    const isPresent = useIsPresent();

    const didClickSchedule = () => {
        scrollToSchedule()
    }

    const didClickSignUp = () => {
        window.open("https://platform.mydevconnect.com/signup", "_blank", "noreferrer");
    }

    const scrollToSchedule = () => {
        scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Header type={2} didClickSchedule={didClickSchedule}/>
                <HRHero didClickSchedule={didClickSchedule} didClickSignUp={didClickSignUp} />
                <WhyUsSection />
                <HowItWorks didClickSchedule={didClickSchedule} />
                <SocialResponsibilitySection />
                <OurPartnersSection />
                <PricingTwo didClickSchedule={didClickSchedule} />
                <ScheduleCall scheduleRef={scheduleRef} scrollToSchedule={scrollToSchedule}/>
                <AboutUsSection />
                <Footer />
            </motion.div>
        </>
    );
}

export default HRFirmsPage;