// WhyUsSection.js
import React from 'react';
import './index.css'; // Ensure the CSS file is linked

function WhyUsSection() {
    return (
        <section className="why-us-section">
            <div className="why-us-content-container">
                <div className="why-us-left-content">
                    <h2 className="why-us-section-mobile-title">Why Us?</h2>
                    <iframe
                        className="video"
                        width="100%"
                        height="400px"
                        src="https://www.youtube.com/embed/i2Ayq0IXSKY?si=GDCTl9bTc8gjh3Sx"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        
                    ></iframe>
                </div>
                <div className="why-us-right-content">
                    <div className="why-us-right-content-container">
                        <h2 className="why-us-section-title">Why Us?</h2>
                        <p>We raise the bar for on-demand staffing:</p>
                        <ul>
                            <li>Bespoke platform</li>
                            <li>Curated talent pool</li>
                            <li>Real-time support</li>
                            <li>Hands-on troubleshooting</li>
                        </ul>
                        <p><strong>No risk involved:</strong> Select your preferred candidates, try new hires at will.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyUsSection;