import React, { useState } from 'react';
import './index.css'; // Ensure the CSS file is linked

function HowItWorks({didClickSchedule}) {

    return (
        <div className="howitworks-section">
            <h1 className="howitworks-section-title">Our Process</h1>
            <div className="howitworks-section-container">
                <div className="howitworks-box one">
                    <div className="howitworks-box-container">
                        <h2 className="howitworks-box-title">Discovery<span className="orange-dot">.</span></h2>
                        <hr />
                        <p className="howitworks-box-description">
                            Define the scope of your work

                        </p>
                        <p className="howitworks-box-description">
                            Explore how our on-demand hires can align with your mission
                        </p>
                        <p className="howitworks-box-description">
                            Layout the objectives you want to achieve
                        </p>
                    </div>
                </div>
                <div className="howitworks-box two">
                    <div className="howitworks-box-container">
                        <h2 className="howitworks-box-title">Direction<span className="orange-dot">.</span></h2>
                        <hr />
                        <p className="howitworks-box-description">
                            We decide on the relevant skillsets/techstack required
                        </p>
                        <p className="howitworks-box-description">
                            We handpick your team that aligns with your needs
                        </p>
                    </div>
                </div>
                <div className="howitworks-box three">
                    <div className="howitworks-box-container">
                        <h2 className="howitworks-box-title">Kick-Off<span className="orange-dot">.</span></h2>
                        <hr />
                        <p className="howitworks-box-description">
                            Setup communication between you and your team.
                        </p>
                        <p className="howitworks-box-description">
                            Get to work!
                        </p>
                        <button className="howitworks-box-schedule-button" onClick={didClickSchedule}>Arrange a call</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;