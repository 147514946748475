import React, { useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import DindaAvatar from "../../images/DindaSari.jpeg"
import JamesAvatar from "../../images/ellipse85.png"
import JessicaAvatar from "../../images/JessicaPatel.jpg"

function OurPartnersSection() {
    const quotes = [
        {
            "image": DindaAvatar,
            "name": "Dinda Sari",
            "quote": "MyDevConnect has been a game-changer for our remote hiring needs. The platform's intuitive interface and vast pool of skilled developers streamlined our process, saving us time and ensuring top-tier talent.  The personalized support from the MyDevConnect team sets them apart, making them an invaluable partner in our growth. If you're serious about building a stellar remote team, look no further – MyDevConnect delivers excellence."
        },
        {
            "image": JamesAvatar,
            "name": "Michael Thompson",
            "quote": "Our project's success soared after partnering with MyDevConnect. Their vetted developers were top-notch, blending seamlessly with our team. Excellent customer support made the entire process smooth. Highly recommend for quality and reliability!"
        },
        {
            "image": JessicaAvatar,
            "name": "Jessica Patel",
            "quote": "MyDevConnect delivered exceptional talent quickly, enhancing our project capabilities. Their ongoing support and commitment to quality were unparalleled. Truly a top-tier partner for any software development needs."
        }
    ]
    const [currentQuote, setCurrentQuote] = useState(quotes[0])
    const [isVisible, setIsVisible] = useState(true)

    const previousQuote = () => {
        handleFade()
        setTimeout(() => {
            const index = quotes.findIndex(quote => quote.name === currentQuote.name)
            if (index == 0) {
                setCurrentQuote(quotes[quotes.length - 1])
            } else {
                setCurrentQuote(quotes[index - 1])
            }
        }, 500);
    }

    const nextQuote = () => {
        handleFade()
        setTimeout(() => {
            const index = quotes.findIndex(quote => quote.name === currentQuote.name)
            if (index == quotes.length - 1) {
                setCurrentQuote(quotes[0])
            } else {
                setCurrentQuote(quotes[index + 1])
            }
        }, 500);
    }

    const handleFade = () => {
        setIsVisible(false); // Set visibility to false to fade out

        // After 0.3 seconds, set visibility back to true to fade in
        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    };

    return (
        <section className="our-partners-section">
            <div className="our-partners-content-container">
                <div className="our-partners-top-content">
                    <div className="our-partners-top-title">
                        <h1>What our partners have to say <span className="orange-dot">...</span></h1>
                    </div>
                    <div className="our-partners-top-buttons">
                        <button onClick={previousQuote}><FontAwesomeIcon icon={faArrowLeft} /></button>
                        <button onClick={nextQuote}><FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </div>
                <div className="our-partners-bottom-content">
                    <div className="our-partners-bottom-comma">
                        <FontAwesomeIcon icon={faQuoteLeft} />
                    </div>
                    <div className={`our-partners-bottom-slider ${isVisible ? 'visible' : ''}`}>
                        <p className="our-partners-bottom-slider-quote">{currentQuote.quote}</p>
                        <div className="our-partners-bottom-slider-author">
                            <img src={currentQuote.image} />
                            <p>{currentQuote.name}</p>
                        </div>
                    </div>
                    <div className="our-partners-bottom-comma">
                        <FontAwesomeIcon icon={faQuoteRight} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurPartnersSection;