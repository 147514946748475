// Hero.js
import React, { useState } from 'react';
import './index.scss'
import StepDown from './StepDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlusCircle, faSeedling, faUserGroup } from '@fortawesome/free-solid-svg-icons';

function HRHero({didClickSchedule,didClickSignUp}) {
    const [isVisible, setIsVisible] = useState(false)
    const [buttonText, setButtonText] = useState()
    const phoneText = "Streamline onboarding through our expertise across sectors and industries, crafting a tailored staffing strategy aligned with your objectives."
    const collaborateText = "Collaborate seamlessly, leveraging strategic global talent, as we guide you through developing and implementing technologies."
    const growText = "Our iterative process gives you peace of mind to grow your capacity and review progress ensuring service delivery for your customers."
    const signUpText = "Scale your workforce with MyDevConnect to discover tailored staffing solutions aligned with your business objectives."

    return (
        <section className="hr-hero">
            <div className="top-spacer"></div>
            <div className="content">
                <div className="left-container">
                    <div className="taglines">
                        <h1 className="main-tagline">
                            <span>Your</span><br />
                            <span>tailored</span><br />
                            <span>on-demand</span><br />
                            <span>workforce</span>
                            <span className="orange-dot"> .</span>
                        </h1>
                        <p className="sub-tagline">High quality vetted talent to suit your needs</p>
                    </div>
                    <div className="buttons-container">
                        <div className="buttons">
                            <button className="button" onClick={didClickSchedule} onMouseOver={() => { setIsVisible(true); setButtonText(phoneText) }} onMouseOut={() => setIsVisible(false)}>
                                <FontAwesomeIcon icon={faPhone} />
                                <p className={isVisible && buttonText == phoneText ? "button-text active" : "button-text"}>Lets talk</p>
                            </button>
                            <button className="button" onMouseOver={() => { setIsVisible(true); setButtonText(collaborateText) }} onMouseOut={() => setIsVisible(false)}>
                                <FontAwesomeIcon icon={faUserGroup} />
                                <p className={isVisible && buttonText == collaborateText ? "button-text active" : "button-text"}>Collaborate</p>
                            </button>
                            <button className="button" onMouseOver={() => { setIsVisible(true); setButtonText(growText) }} onMouseOut={() => setIsVisible(false)}>
                                <FontAwesomeIcon icon={faSeedling} />
                                <p className={isVisible && buttonText == growText ? "button-text active" : "button-text"}>Grow Together</p>
                            </button>
                            <button className="button" onClick={didClickSignUp} onMouseOver={() => { setIsVisible(true); setButtonText(signUpText) }} onMouseOut={() => setIsVisible(false)}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                                <p className={isVisible && buttonText == signUpText ? "button-text active" : "button-text"}>Sign Up</p>
                            </button>
                        </div>
                    </div>
                    <div className="hero-button-info">
                        <div className={isVisible ? "hero-button-info-container visible" : "hero-button-info-container"}>
                            <p>{buttonText}</p>
                        </div>
                    </div>
                </div>
                <div className="right-container">
                </div>
            </div>
            <div className='bottom-content'>
                <StepDown />
            </div>
        </section>
    );
}

export default HRHero;