export const pricing_data = {
    "tech_dev": {
        "full_stack": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/4gw8A465mf3VdKofZa",
                    "name": "Foundation - Full Stack"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/14keYs65mcVN6hW4gu",
                    "name": "Growth - Full Stack"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/aEUcQkctK6xp9u84gw",
                    "name": "Scale - Full Stack"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/3csg2wfFWaNFeOs5kx",
                    "name": "Foundation Annual - Full Stack",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/5kA3fK1P64ph8q4aET",
                    "name": "Growth Annual - Full Stack",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/14kcQk8du7BteOscN3",
                    "name": "Scale Annual - Full Stack",
                    "discount_monthly": "£3,010"
                }
            }
        },
        "front_end": {
            "monthly": {
                "plan_1": {
                    "price": "£1,700",
                    "link": "https://buy.stripe.com/7sI6rWeBS4phaycdR8",
                    "name": "Foundation - Front End"
                },
                "plan_2": {
                    "price": "£2,720",
                    "link": "https://buy.stripe.com/00g8A4bpGdZR7m03cw",
                    "name": "Growth - Front End"
                },
                "plan_3": {
                    "price": "£2,992",
                    "link": "https://buy.stripe.com/aEU8A4gK08Fx35KcN8",
                    "name": "Scale - Front End"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£18,360",
                    "link": "https://buy.stripe.com/8wMg2w1P6g7Z21GbJ1",
                    "name": "Foundation Annual - Front End",
                    "discount_monthly": "£1,530"
                },
                "plan_2": {
                    "price": "£29,376",
                    "link": "https://buy.stripe.com/aEUg2wfFW2h949OaEZ",
                    "name": "Growth Annual - Front End",
                    "discount_monthly": "£2,448"
                },
                "plan_3": {
                    "price": "£32,314",
                    "link": "https://buy.stripe.com/00gg2w65mdZR9u8cN9",
                    "name": "Scale Annual - Front End",
                    "discount_monthly": "£2,693"
                }
            }
        },
        "back_end": {
            "monthly": {
                "plan_1": {
                    "price": "£1,700",
                    "link": "https://buy.stripe.com/eVadUo3Xe6xp5dSdRe",
                    "name": "Foundation - Back End"
                },
                "plan_2": {
                    "price": "£2,720",
                    "link": "https://buy.stripe.com/fZeeYsalCaNFcGk7sS",
                    "name": "Growth - Back End"
                },
                "plan_3": {
                    "price": "£2,992",
                    "link": "https://buy.stripe.com/aEU6rW2Ta9JB5dS4gI",
                    "name": "Scale - Back End"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£18,360",
                    "link": "https://buy.stripe.com/28o3fKdxO3ld5dSaF3",
                    "name": "Foundation Annual - Back End",
                    "discount_monthly": "£1,530"
                },
                "plan_2": {
                    "price": "£29,376",
                    "link": "https://buy.stripe.com/aEUaIc9hy7Bt7m03cD",
                    "name": "Growth Annual - Back End",
                    "discount_monthly": "£2,448"
                },
                "plan_3": {
                    "price": "£32,314",
                    "link": "https://buy.stripe.com/6oE3fKfFW6xp5dS14x",
                    "name": "Scale Annual - Back End",
                    "discount_monthly": "£2,693"
                }
            }
        },
        "data_engineer": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/14k7w00L29JB35K7sW",
                    "name": "Foundation - AI & Data"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/6oE9E80L25tlfSw5kQ",
                    "name": "Growth - AI & Data"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/6oE2bG2Ta7Bt21G9B8",
                    "name": "Scale - AI & Data"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/fZeeYseBS6xpeOs9B5",
                    "name": "Foundation Annual - AI & Data",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/aEU7w09hyaNF35KfZv",
                    "name": "Growth Annual - AI & Data",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/6oEdUo0L2dZR6hW9B9",
                    "name": "Scale Annual - AI & Data",
                    "discount_monthly": "£3,010"
                }
            }
        },
        "ml_and_lm": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/14k7w00L29JB35K7sW",
                    "name": "Foundation - AI & Data"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/6oE9E80L25tlfSw5kQ",
                    "name": "Growth - AI & Data"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/6oE2bG2Ta7Bt21G9B8",
                    "name": "Scale - AI & Data"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/fZeeYseBS6xpeOs9B5",
                    "name": "Foundation Annual - AI & Data",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/aEU7w09hyaNF35KfZv",
                    "name": "Growth Annual - AI & Data",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/6oEdUo0L2dZR6hW9B9",
                    "name": "Scale Annual - AI & Data",
                    "discount_monthly": "£3,010"
                }
            }
        },
        "dev_ops": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/14k7w00L29JB35K7sW",
                    "name": "Foundation - AI & Data"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/6oE9E80L25tlfSw5kQ",
                    "name": "Growth - AI & Data"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/6oE2bG2Ta7Bt21G9B8",
                    "name": "Scale - AI & Data"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/fZeeYseBS6xpeOs9B5",
                    "name": "Foundation Annual - AI & Data",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/aEU7w09hyaNF35KfZv",
                    "name": "Growth Annual - AI & Data",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/6oEdUo0L2dZR6hW9B9",
                    "name": "Scale Annual - AI & Data",
                    "discount_monthly": "£3,010"
                }
            }
        },
        "mobile": {
            "monthly": {
                "plan_1": {
                    "price": "£1,700",
                    "link": "https://buy.stripe.com/4gw5nS9hy0918q44gQ",
                    "name": "Foundation - Mobile"
                },
                "plan_2": {
                    "price": "£2,720",
                    "link": "https://buy.stripe.com/14kdUo0L2g7Zayc5kW",
                    "name": "Growth - Mobile"
                },
                "plan_3": {
                    "price": "£2,992",
                    "link": "https://buy.stripe.com/cN29E851i9JB9u84gU",
                    "name": "Scale - Mobile"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£18,360",
                    "link": "https://buy.stripe.com/bIY6rW1P64ph0XCdRr",
                    "name": "Foundation Annual - Mobile",
                    "discount_monthly": "£1,530"
                },
                "plan_2": {
                    "price": "£29,376",
                    "link": "https://buy.stripe.com/cN28A4ctKaNF0XCfZB",
                    "name": "Growth Annual - Mobile",
                    "discount_monthly": "£2,448"
                },
                "plan_3": {
                    "price": "£32,314",
                    "link": "https://buy.stripe.com/8wM3fK51i091ayc28N",
                    "name": "Scale Annual - Mobile",
                    "discount_monthly": "£2,693"
                }
            }
        },
        "ui_ux": {
            "monthly": {
                "plan_1": {
                    "price": "£1,400",
                    "link": "https://buy.stripe.com/8wMdUoalCg7Z21G5l0",
                    "name": "Foundation - UI/UX"
                },
                "plan_2": {
                    "price": "£2,240",
                    "link": "https://buy.stripe.com/fZe03y79q6xp7m0aFm",
                    "name": "Growth - UI/UX"
                },
                "plan_3": {
                    "price": "£2,464",
                    "link": "https://buy.stripe.com/aEUbMgfFW3ldfSw3cW",
                    "name": "Scale - UI/UX"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£15,120",
                    "link": "https://buy.stripe.com/3cscQkgK03ldayc00H",
                    "name": "Foundation Annual - UI/UX",
                    "discount_monthly": "£1,260"
                },
                "plan_2": {
                    "price": "£24,192",
                    "link": "https://buy.stripe.com/28oaIc0L26xp0XC3cV",
                    "name": "Growth Annual - UI/UX",
                    "discount_monthly": "£2,016"
                },
                "plan_3": {
                    "price": "£26,611",
                    "link": "https://buy.stripe.com/eVacQk9hy091eOsdRB",
                    "name": "Scale Annual - UI/UX",
                    "discount_monthly": "£2,218"
                }
            }
        },
        "graphic": {
            "monthly": {
                "plan_1": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/bIY03y8du4ph49OcNy",
                    "name": "Foundation - Graphic Design"
                },
                "plan_2": {
                    "price": "£1,920",
                    "link": "https://buy.stripe.com/eVa6rW3XebRJbCg5l8",
                    "name": "Growth - Graphic Design"
                },
                "plan_3": {
                    "price": "£2,112",
                    "link": "https://buy.stripe.com/5kAeYsfFW4phdKodRG",
                    "name": "Scale - Graphic Design"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/6oE7w065m1d50XC5l7",
                    "name": "Foundation Annual - Graphic Design",
                    "discount_monthly": "£1,080"
                },
                "plan_2": {
                    "price": "£20,736",
                    "link": "https://buy.stripe.com/cN2bMgdxObRJgWA28X",
                    "name": "Growth Annual - Graphic Design",
                    "discount_monthly": "£1,728"
                },
                "plan_3": {
                    "price": "£22,810",
                    "link": "https://buy.stripe.com/7sIeYs3Xe1d5fSweVL",
                    "name": "Scale Annual - Graphic Design",
                    "discount_monthly": "£1,901"
                }
            }
        },
        "project_manager": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/28o03y0L2cVNgWA7tk",
                    "name": "Foundation - Project Manager / Lead Architect"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/6oE7w0bpG7BtgWA5le",
                    "name": "Growth - Project Manager / Lead Architect"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/6oEdUo51if3V21G8xs",
                    "name": "Scale - Project Manager / Lead Architect"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/4gwaIcgK08Fxayc7tl",
                    "name": "Foundation Annual - Project Manager / Lead Architect",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/9AQ7w0eBS6xpfSwaFz",
                    "name": "Growth Annual - Project Manager / Lead Architect",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/00geYs2Ta091eOs6pl",
                    "name": "Scale Annual - Project Manager / Lead Architect",
                    "discount_monthly": "£3,010"
                }
            }
        }
    },
    "finance_hr": {
        "bookkeepers": {
            "monthly": {
                "plan_1": {
                    "price": "£800",
                    "link": "https://buy.stripe.com/8wM3fK79q3ld6hWdRO",
                    "name": "Foundation - Bookkeepers"
                },
                "plan_2": {
                    "price": "£1,280",
                    "link": "https://buy.stripe.com/cN2eYsgK0g7Z9u89BA",
                    "name": "Growth - Bookkeepers"
                },
                "plan_3": {
                    "price": "£1,408",
                    "link": "https://buy.stripe.com/dR6eYs65m5tlfSw6pq",
                    "name": "Scale - Bookkeepers"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,640",
                    "link": "https://buy.stripe.com/dR62bG9hy5tlgWAdRP",
                    "name": "Foundation Annual - Bookkeepers",
                    "discount_monthly": "£720"
                },
                "plan_2": {
                    "price": "£13,824",
                    "link": "https://buy.stripe.com/cN2aIc8du8Fx7m0cNN",
                    "name": "Growth Annual - Bookkeepers",
                    "discount_monthly": "£1,152"
                },
                "plan_3": {
                    "price": "£15,206",
                    "link": "https://buy.stripe.com/dR6aIc2TacVN9u8aFH",
                    "name": "Scale Annual - Bookkeepers",
                    "discount_monthly": "£1,267"
                }
            }
        },
        "chartered_accountants": {
            "monthly": {
                "plan_1": {
                    "price": "£1,900",
                    "link": "https://buy.stripe.com/8wMcQkdxOf3VdKocNQ",
                    "name": "Foundation - Chartered Accountants"
                },
                "plan_2": {
                    "price": "£3,040",
                    "link": "https://buy.stripe.com/8wM2bGdxO7Bt8q49BG",
                    "name": "Growth - Chartered Accountants"
                },
                "plan_3": {
                    "price": "£3,344",
                    "link": "https://buy.stripe.com/8wM9E8gK0g7Z21G7tA",
                    "name": "Scale - Chartered Accountants"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£20,520",
                    "link": "https://buy.stripe.com/7sIdUoctK8Fx9u8eVZ",
                    "name": "Foundation Annual - Chartered Accountants",
                    "discount_monthly": "£1,710"
                },
                "plan_2": {
                    "price": "£32,832",
                    "link": "https://buy.stripe.com/00gaIcdxOg7ZdKo5lr",
                    "name": "Growth Annual - Chartered Accountants",
                    "discount_monthly": "£2,736"
                },
                "plan_3": {
                    "price": "£36,115",
                    "link": "https://buy.stripe.com/5kA6rW8du1d56hW3dl",
                    "name": "Scale Annual - Chartered Accountants",
                    "discount_monthly": "£3,010"
                }
            }
        },
        "cpas": {
            "monthly": {
                "plan_1": {
                    "price": "£2,600",
                    "link": "https://buy.stripe.com/fZe8A41P64ph35K7tC",
                    "name": "Foundation - CPAs"
                },
                "plan_2": {
                    "price": "£4,160",
                    "link": "https://buy.stripe.com/aEU4jOalC5tl8q415g",
                    "name": "Growth - CPAs"
                },
                "plan_3": {
                    "price": "£4,576",
                    "link": "https://buy.stripe.com/7sI2bG1P68Fx7m0eW8",
                    "name": "Scale - CPAs"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£28,080",
                    "link": "https://buy.stripe.com/6oE4jOctKbRJ5dS6pz",
                    "name": "Foundation Annual - CPAs",
                    "discount_monthly": "£2,340"
                },
                "plan_2": {
                    "price": "£44,928",
                    "link": "https://buy.stripe.com/5kA8A49hyaNFayccNZ",
                    "name": "Growth Annual - CPAs",
                    "discount_monthly": "£3,744"
                },
                "plan_3": {
                    "price": "£49,421",
                    "link": "https://buy.stripe.com/cN23fKeBS1d5gWA4hv",
                    "name": "Scale Annual - CPAs",
                    "discount_monthly": "£4,118"
                }
            }
        },
        "hr_managers": {
            "monthly": {
                "plan_1": {
                    "price": "£1,700",
                    "link": "https://buy.stripe.com/28obMg79q3ld0XC29o",
                    "name": "Foundation - HR Managers"
                },
                "plan_2": {
                    "price": "£2,720",
                    "link": "https://buy.stripe.com/00g5nS9hy7Bt21G7tK",
                    "name": "Growth - HR Managers"
                },
                "plan_3": {
                    "price": "£2,992",
                    "link": "https://buy.stripe.com/cN2eYs65maNF49O4hA",
                    "name": "Scale - HR Managers"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£18,360",
                    "link": "https://buy.stripe.com/bIY9E8dxO7Bt7m015l",
                    "name": "Foundation Annual - HR Managers",
                    "discount_monthly": "£1,530"
                },
                "plan_2": {
                    "price": "£29,376",
                    "link": "https://buy.stripe.com/cN22bGctK1d5ayc01j",
                    "name": "Growth Annual - HR Managers",
                    "discount_monthly": "£2,448"
                },
                "plan_3": {
                    "price": "£32,314",
                    "link": "https://buy.stripe.com/eVadUogK07Bt9u801l",
                    "name": "Scale Annual - HR Managers",
                    "discount_monthly": "£2,693"
                }
            }
        }
    },
    "bus_dev": {
        "support": {
            "monthly": {
                "plan_1": {
                    "price": "£750",
                    "link": "https://buy.stripe.com/28oaIc0L25tl21G15q",
                    "name": "Foundation - Customer Support"
                },
                "plan_2": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/6oEcQk2Ta4phcGkbK6",
                    "name": "Growth - Customer Support"
                },
                "plan_3": {
                    "price": "£1,320",
                    "link": "https://buy.stripe.com/6oE2bGctKdZR21G5lK",
                    "name": "Scale - Customer Support"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,100",
                    "link": "https://buy.stripe.com/9AQ7w03XedZRbCgeWh",
                    "name": "Foundation Annual - Customer Support",
                    "discount_monthly": "£675"
                },
                "plan_2": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/8wM6rW0L29JBgWA8xV",
                    "name": "Growth Annual - Customer Support",
                    "discount_monthly": "£1,080"
                },
                "plan_3": {
                    "price": "£14,256",
                    "link": "https://buy.stripe.com/7sIeYs1P6dZR49O7tT",
                    "name": "Scale Annual - Customer Support",
                    "discount_monthly": "£1,188"
                }
            }
        },
        "marketing": {
            "monthly": {
                "plan_1": {
                    "price": "£750",
                    "link": "https://buy.stripe.com/5kAeYsgK0g7Z49O8xY",
                    "name": "Foundation - Marketing Support"
                },
                "plan_2": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/3cscQk65mg7Zayc9C4",
                    "name": "Growth - Marketing Support"
                },
                "plan_3": {
                    "price": "£1,320",
                    "link": "https://buy.stripe.com/cN28A48duaNF8q4bKe",
                    "name": "Scale - Marketing Support"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,100",
                    "link": "https://buy.stripe.com/4gw17C8dudZR8q47tV",
                    "name": "Foundation Annual - Marketing Support",
                    "discount_monthly": "£675"
                },
                "plan_2": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/7sI17C2TaaNFayc01v",
                    "name": "Growth Annual - Marketing Support",
                    "discount_monthly": "£1,080"
                },
                "plan_3": {
                    "price": "£14,256",
                    "link": "https://buy.stripe.com/28o03ybpG3ld49OaGb",
                    "name": "Scale Annual - Marketing Support",
                    "discount_monthly": "£1,188"
                }
            }
        },
        "social_dev": {
            "monthly": {
                "plan_1": {
                    "price": "£750",
                    "link": "https://buy.stripe.com/bIYg2wbpGdZR8q4g0w",
                    "name": "Foundation - Social Media Development"
                },
                "plan_2": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/3cs6rWfFW6xp35KaGe",
                    "name": "Growth - Social Media Development"
                },
                "plan_3": {
                    "price": "£1,320",
                    "link": "https://buy.stripe.com/28o7w0gK05tlayceWw",
                    "name": "Scale - Social Media Development"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,100",
                    "link": "https://buy.stripe.com/cN203ybpG6xp6hW3dL",
                    "name": "Foundation Annual - Social Media Development",
                    "discount_monthly": "£675"
                },
                "plan_2": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/9AQ3fK2Ta09121GeWv",
                    "name": "Growth Annual - Social Media Development",
                    "discount_monthly": "£1,080"
                },
                "plan_3": {
                    "price": "£14,256",
                    "link": "https://buy.stripe.com/dR69E88du7BtgWAaGh",
                    "name": "Scale Annual - Social Media Development",
                    "discount_monthly": "£1,188"
                }
            }
        },
        "sales_outreach": {
            "monthly": {
                "plan_1": {
                    "price": "£750",
                    "link": "https://buy.stripe.com/bIY8A48dug7Z21G5lY",
                    "name": "Foundation - Sales and Outreach"
                },
                "plan_2": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/3cseYsalC7Bt49O8yc",
                    "name": "Growth - Sales and Outreach"
                },
                "plan_3": {
                    "price": "£1,320",
                    "link": "https://buy.stripe.com/bIY7w0dxO8Fx0XCg0G",
                    "name": "Scale - Sales and Outreach"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,100",
                    "link": "https://buy.stripe.com/7sI03y0L28FxbCg9Cf",
                    "name": "Foundation Annual - Sales and Outreach",
                    "discount_monthly": "£675"
                },
                "plan_2": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/bIYdUoctK1d59u815L",
                    "name": "Growth Annual - Sales and Outreach",
                    "discount_monthly": "£1,080"
                },
                "plan_3": {
                    "price": "£14,256",
                    "link": "https://buy.stripe.com/bIYcQk79q6xp0XCbKr",
                    "name": "Scale Annual - Sales and Outreach",
                    "discount_monthly": "£1,188"
                }
            }
        }
    },
    "admin_assist": {
        "exec_assist": {
            "monthly": {
                "plan_1": {
                    "price": "£800",
                    "link": "https://buy.stripe.com/fZebMgfFWg7Z8q4g0I",
                    "name": "Foundation - Executive Assistants"
                },
                "plan_2": {
                    "price": "£1,280",
                    "link": "https://buy.stripe.com/28obMgbpG6xpaycg0K",
                    "name": "Growth - Executive Assistants"
                },
                "plan_3": {
                    "price": "£1,408",
                    "link": "https://buy.stripe.com/3cseYs2Ta4ph7m0g0M",
                    "name": "Scale - Executive Assistants"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,640",
                    "link": "https://buy.stripe.com/8wM03y51i3ld0XCdSB",
                    "name": "Foundation Annual - Executive Assistants",
                    "discount_monthly": "£720"
                },
                "plan_2": {
                    "price": "£13,824",
                    "link": "https://buy.stripe.com/4gwg2wctK3ld9u8bKv",
                    "name": "Growth Annual - Executive Assistants",
                    "discount_monthly": "£1,152"
                },
                "plan_3": {
                    "price": "£15,206",
                    "link": "https://buy.stripe.com/6oE03y51i1d5gWAdSF",
                    "name": "Scale Annual - Executive Assistants",
                    "discount_monthly": "£1,267"
                }
            }
        },
        "admin_support_staff": {
            "monthly": {
                "plan_1": {
                    "price": "£700",
                    "link": "https://buy.stripe.com/9AQ4jO79qg7ZfSw3e2",
                    "name": "Foundation - Administrative Support Staff"
                },
                "plan_2": {
                    "price": "£1,120",
                    "link": "https://buy.stripe.com/00g9E851if3V21G9Cs",
                    "name": "Growth - Administrative Support Staff"
                },
                "plan_3": {
                    "price": "£1,232",
                    "link": "https://buy.stripe.com/8wMaIcfFW1d55dS9Cu",
                    "name": "Scale - Administrative Support Staff"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£7,560",
                    "link": "https://buy.stripe.com/4gw8A4fFW1d549O7uj",
                    "name": "Foundation Annual - Administrative Support Staff",
                    "discount_monthly": "£630"
                },
                "plan_2": {
                    "price": "£12,096",
                    "link": "https://buy.stripe.com/7sI3fK0L2g7Z9u8g0R",
                    "name": "Growth Annual - Administrative Support Staff",
                    "discount_monthly": "£1,008"
                },
                "plan_3": {
                    "price": "£13,306",
                    "link": "https://buy.stripe.com/4gw8A49hy6xpgWA2a3",
                    "name": "Scale Annual - Administrative Support Staff",
                    "discount_monthly": "£1,109"
                }
            }
        },
        "legal_assist": {
            "monthly": {
                "plan_1": {
                    "price": "£800",
                    "link": "https://buy.stripe.com/bIY6rW79q1d5dKo6qk",
                    "name": "Foundation - Legal Assistants"
                },
                "plan_2": {
                    "price": "£1,280",
                    "link": "https://buy.stripe.com/dR65nS3Xe6xp8q4aGC",
                    "name": "Growth - Legal Assistants"
                },
                "plan_3": {
                    "price": "£1,408",
                    "link": "https://buy.stripe.com/bIY4jO79qaNF35KaGE",
                    "name": "Scale - Legal Assistants"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,640",
                    "link": "https://buy.stripe.com/4gwg2w65m1d5dKocOJ",
                    "name": "Foundation Annual - Legal Assistants",
                    "discount_monthly": "£720"
                },
                "plan_2": {
                    "price": "£13,824",
                    "link": "https://buy.stripe.com/14k7w0dxOdZR9u83eb",
                    "name": "Growth Annual - Legal Assistants",
                    "discount_monthly": "£1,152"
                },
                "plan_3": {
                    "price": "£15,206",
                    "link": "https://buy.stripe.com/28o5nSeBSdZRfSwaGF",
                    "name": "Scale Annual - Legal Assistants",
                    "discount_monthly": "£1,267"
                }
            }
        },
        "personal_assist": {
            "monthly": {
                "plan_1": {
                    "price": "£750",
                    "link": "https://buy.stripe.com/8wMbMg0L28FxeOs166",
                    "name": "Foundation - Personal Assistants"
                },
                "plan_2": {
                    "price": "£1,200",
                    "link": "https://buy.stripe.com/5kA8A41P62h949O5mo",
                    "name": "Growth - Personal Assistants"
                },
                "plan_3": {
                    "price": "£1,320",
                    "link": "https://buy.stripe.com/bIY6rWeBS6xpcGk5mq",
                    "name": "Scale - Personal Assistants"
                }
            },
            "annual": {
                "plan_1": {
                    "price": "£8,100",
                    "link": "https://buy.stripe.com/aEU5nS65mg7Z0XC9CD",
                    "name": "Foundation Annual - Personal Assistants",
                    "discount_monthly": "£675"
                },
                "plan_2": {
                    "price": "£12,960",
                    "link": "https://buy.stripe.com/eVa8A41P6cVNcGk5mp",
                    "name": "Growth Annual - Personal Assistants",
                    "discount_monthly": "£1,080"
                },
                "plan_3": {
                    "price": "£14,256",
                    "link": "https://buy.stripe.com/dR67w051i5tl21G16b",
                    "name": "Scale Annual - Personal Assistants",
                    "discount_monthly": "£1,188"
                }
            }
        }
    }
}