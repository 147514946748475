import React from 'react';
import './index.css';
import Logo from "../../images/logos/MDCLogoWhite.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <section className="footer-section">
            <div className="footer-content-container">
                <div className="footer-left-content">
                    <img className="footer-logo" src={Logo} />
                    <p className="footer-copyright">Copyright 2024</p>
                </div>
                <div className="footer-right-content">
                    <div className="footer-right-comma">
                        <FontAwesomeIcon style={{color:"#fff"}} icon={faQuoteLeft} />
                    </div>
                    <div className="footer-right-quote-box">
                        <p>Industry leading service delivery for startups, scale-ups and MSMEs<span className="orange-dot">.</span></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;