// WhyUsSection.js
import React from 'react';
import './index.css'; // Ensure the CSS file is linked

function AboutUsSection() {

    return (
        <section className="aboutus-section">
            <div className="aboutus-content-container">
                <div className="aboutus-left-content">
                    <div className="pretty-box">
                        <p>About Us <span className="orange-dot">.</span></p>
                    </div>
                </div>
                <div className="aboutus-right-content">
                    <p>MyDevConnect was founded by a passionate team of entrepreneurs, developers and designers, from the USA, UK, Europe, and Africa.</p>
                    <br/>
                    <p>We seek to support companies and founders from ideation to monetisation, providing all necessary hires for you to build projects, products, MVPs, go-to-market, test, and scale your goods, services, and technologies.</p>
                    <br/>
                    <p>There’s a higher standard to set for on-demand hires, specifically in providing better service delivery, educated talent pools at competitive costs, the flexibility to grow with our clients, and building the skills of our employees to set the industry standard.</p>
                    <br/>
                </div>
            </div>
            <div className="aboutus-right-spacer"></div>
        </section>
    )
}

export default AboutUsSection;