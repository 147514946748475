import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { organisationChoicePageAnimationVariant, organisationChoicePageTransition } from '../../variants';
import './index.scss'
import Lottie from 'lottie-react';
import animationData from '../../eyes-animation.json';
import { useNavigate } from 'react-router-dom';


function OrganisationChoice() {
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [isHovering, setIsHovering] = useState(false);
    const navigate = useNavigate();
    const controls = useAnimation();

    const handleMouseMove = (e) => {
        setCursorPos({ x: e.clientX, y: e.clientY });
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const openHRPage = async () => {
        await controls.start({
            y: [0,"-100vh"],
            height: [0,"100vh"],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            }
        });
        navigate("/for-hr")
    }
    
    const openCompanyPage = async () => {
        await controls.start({
            y: [0,"-100vh"],
            height: [0,"100vh"],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            }
        });
        navigate("/for-companies")
    }

    return (
        <div className="organisation-section">
            <motion.div
                className="org-choice-container"
                initial="initial"
                animate="in"
                variants={organisationChoicePageAnimationVariant}
                transition={organisationChoicePageTransition}
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <motion.div
                    className="org-choice-column org-choice-company"
                    whileHover={{ scale: [null, 1.2, 1.15] }}
                    transition={{ duration: 0.3 }}
                    whileTap={{scale:[null, 0.8]}}
                    onClick={openCompanyPage}
                >
                    <p className="org-choice-column-title">Companies</p>
                    <ul>
                        <li>Increase development capacity</li>

                        <li>Develop projects or MVP’s</li>

                        <li>Save up to 60 percent on skilled talent compared to local rates.</li>
                    </ul>
                </motion.div>
                <motion.div
                    className="org-choice-seperator"
                    animate={{
                        y: ["-100vh", 0]
                    }}
                    transition={{
                        delay: 0.5,
                        duration: 0.3,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                    }}
                >
                </motion.div>
                <motion.div
                    className="org-choice-column org-choice-hr"
                    whileHover={{ scale: [null, 1.2, 1.15] }}
                    transition={{ duration: 0.3 }}
                    whileTap={{scale:[null, 0.8]}}
                    onClick={openHRPage}
                >
                    <p className="org-choice-column-title">HR Firms</p>
                    <ul>
                        <li>Providing talented, vetted and tested workforces at scale.</li>

                        <li>The technology to manage candidate pools in mass</li>

                        <li>All accessible at ease in one place, on our bespoke platform.</li>
                    </ul>
                </motion.div>
                <div
                    className="cursor-div"
                    style={{
                        background:"white",
                        height: "50px",
                        width: "50px",
                        padding: "25px",
                        borderRadius: "50px",
                        top: cursorPos.y,
                        left: cursorPos.x,
                        display: isHovering ? 'block' : 'none',
                    }}
                >
                    <Lottie animationData={animationData} loop={true} />
                </div>
            </motion.div>
            <motion.div
                className="org-initial-overlay"
                animate={{
                    y: ["-100vh"],
                    x: ["0", "100vw"],
                    width: ["100vw", 0]
                }}
                transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    times: [0, 0.2, 0.5, 0.8, 1],
                }}
            ></motion.div>
            <motion.div
                className="org-exit-overlay"
                animate={controls}
                initial={{ y: "0", height: "0" }}
            ></motion.div>
        </div>
    );
}

export default OrganisationChoice;