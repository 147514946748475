import React from 'react';
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function StepDown() {
    return (
        <section className="how-it-works">
            <div className="how-it-works-content-container">
                <div className="how-it-works-left-content">
                    <div className="how-it-works-left-content-container">
                        <p className="how-it-works-title">How it works</p>
                        <p className="how-it-works-description">We stand out with personalized, high-quality services that go beyond traditional outsourcing.</p>
                    </div>
                </div>
                <div className="how-it-works-step-box">
                    <button><FontAwesomeIcon icon={faArrowDown} /></button>
                </div>
            </div>
        </section>
    );
}

export default StepDown;