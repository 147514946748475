import React, { useRef } from 'react';
import { motion, useAnimation, useIsPresent } from 'framer-motion';
import CompaniesHero from './CompaniesHero';
import WhatWeOffer from './WhatWeOffer';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { pageVariants, pageTransition, organisationChoicePageAnimationVariant, organisationChoicePageTransition } from '../../variants';
import styles from './index.module.scss'
import HowWeDoIt from './HowWeDoIt';
import ReadyToTalk from './ReadyToTalk';

function CompaniesPage() {
    const scheduleRef = useRef(null)

    const didClickSchedule = () => {
        scrollToSchedule()
    }

    const didClickSignUp = () => {
        window.open("https://platform.mydevconnect.com/signup", "_blank", "noreferrer");
    }

    const scrollToSchedule = () => {
        scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <div className={styles.companiesPage}>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={organisationChoicePageAnimationVariant}
                transition={organisationChoicePageTransition}
            >
                <Header type={1} didClickSchedule={didClickSchedule} />
                <CompaniesHero />
                <WhatWeOffer />
                <HowWeDoIt />
                <ReadyToTalk scheduleRef={scheduleRef} scrollToSchedule={scrollToSchedule}/>
                <Footer />
            </motion.div>
        </div>
    );
}

export default CompaniesPage;