import React, { useRef, useState } from 'react';
import { motion, useAnimation, useIsPresent } from 'framer-motion';
import { pageVariants, pageTransition, homePageAnimationVariant, homePageTransition } from '../../variants';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss'

function Home() {
    const navigate = useNavigate();
    const scheduleRef = useRef(null)
    const controls = useAnimation();

    const openChoicePage = async () => {
        await controls.start({
            y: [0,"-100vh"],
            height: [0,"100vh"],
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            }
        });
        navigate("/organisation-choice");
    }

    const didClickSchedule = () => {
        scrollToSchedule()
    }

    const didClickSignUp = () => {
        window.open("https://platform.mydevconnect.com/signup", "_blank", "noreferrer");
    }

    const scrollToSchedule = () => {
        scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                variants={homePageAnimationVariant}
                transition={homePageTransition}
            >
                <section className={styles.hero}>
                    <div className={styles.topSpacer}></div>
                    <div className={styles.content}>
                        <div className={styles.taglines}>
                            <h1 className={styles.main}>
                                {/* We provide individual talent, teams and workforces at scale, to businesses and HR firms managed seamlessly through our technology */}
                                {/* Providing software developers, teams and workforces at scale, to businesses and HR firms. That's MyDevConnect */}
                                Providing businesses and HR firms with developers, teams and custom software at scale. That's MyDevConnect
                                <span className={styles.orangeDot}> .</span>
                            </h1>

                            <motion.div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                                animate={{
                                    scaleY: [0, 1]
                                }}
                                transition={{
                                    delay: 1,
                                    duration: 0.3,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                }}
                            >
                                <motion.div className={styles.button} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                                    <button onClick={openChoicePage}>Follow the path of least resistance</button>
                                    <div className={styles.dottedLine}></div>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                </section>
            </motion.div>
            <motion.div
                className={styles.homeInitialOverlay}
                animate={{
                    y: ["-100vh", 0],
                    height: ["100vh", 0]
                }}
                initial={{ y: "0", height: "0" }}
                transition={{
                    duration: 0.8,
                    ease: "easeInOut",
                }}
            ></motion.div>
            <motion.div
                className={styles.homeExitOverlay}
                animate={controls}
                initial={{ y: "0", height: "0" }}
            ></motion.div>
        </>
    );
}

export default Home;