// WhyUsSection.js
import React from 'react';
import './index.css'; // Ensure the CSS file is linked

function SocialResponsibilitySection() {
    return (
        <section className="sr-section">
            <div className="sr-left-spacer"></div>
            <div className="sr-content-container">
                <div className="sr-left-content">
                    <h2 className="sr-section-title">Social Responsibility</h2>
                    <p>MyDevConnect empowers young professionals in Africa, through carefully selecting the best candidates from the best universities, while placing them in positions of global recognition at companies worldwide.</p>
                    <br/>
                    <p>We ensure successful employment opportunities through education, skills training, and capacity building on-the-job, ensuring African talent remains increasingly capable and competitive as standards increase globally.</p>
                </div>
                <div className="sr-right-content">
                    <div className="pretty-box">
                        <p className="sr-right-content-text">Our vibrant culture fosters creativity, community engagement, and social impact, reflecting our commitment to excellence <span className="orange-dot">.</span></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SocialResponsibilitySection;