import styles from './index.module.scss'

function WhatWeOffer() {
    return (
        <section className={styles.whatweofferSection}>
            <div className={styles.title}>
                <h2>We offer you...</h2>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <h2>
                        The capacity to build anything you need
                    </h2>
                    <p>
                        We bring the combined management and industry knowledge, with a skilled team specific to your needs and take on your build.
                    </p>
                </div>
                <div className={styles.right}>
                    <div className={styles.line}></div>
                    <div className={styles.bubble}>1</div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.right}>
                    <div className={styles.bubble}>2</div>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.left}>
                    <h2>
                        The talent you can rely on to integrate into your existing team
                    </h2>
                    <p>
                        You tell us what stacks you're working with, the culture of your current team and how you want your new hires to integrate, we give you the best people to fulfil those needs.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default WhatWeOffer;