import React, { useEffect, useState } from 'react';
import './index.css'; // Ensure the CSS file is linked
import './staff_options.css'; // Ensure the CSS file is linked
import './staff_options_mobile.css'; // Ensure the CSS file is linked
import { pricing_data } from './pricing_data';
import { Link } from 'react-router-dom';

function PricingTwo({didClickSchedule}) {
    const [isMonthlyMode, setIsMonthlyMode] = useState(true)
    const [isVisible, setIsVisible] = useState(true)
    const [currentCategory, setCurrentCategory] = useState("tech_dev")
    const [currentSubCategory, setCurrentSubCategory] = useState("front_end")
    const [currentPricing, setCurrentPricing] = useState(pricing_data[currentCategory][currentSubCategory])

    const setMonthly = (value) => {
        setIsVisible(false); // Set visibility to false to fade out

        // After 0.3 seconds, set visibility back to true to fade in
        setTimeout(() => {
            setIsMonthlyMode(value);
            setIsVisible(true);
        }, 300);
    };
    /* 10% off all yearly prices*/

    const didClickCategory = (name) => {
        console.log(currentCategory)
        var d = document.getElementById(name);

        if (d.checked == true) {
            var sub = document.getElementById(name + "_sub");
            sub.style.display = "block";

            // Remove previous if necessary
            var previous = document.getElementById(currentCategory);
            if (previous && previous.checked == true) {
                console.log("prev ", previous.name)
                previous.checked = false

                var prev_sub = document.getElementById(previous.name + "_sub");
                prev_sub.style.display = "none";
            }

            // Set new category
            setCurrentCategory(name)
            didClickSubCategory(name, Object.keys(pricing_data[name])[0])
        }
    }

    const didClickSubCategory = (main_name, sub_name) => {
        const newSelected = document.getElementById(sub_name);
        if (!newSelected.classList.contains("selected")) {
            console.log("New sub " + sub_name)

            // Remove previous if necessary
            if (currentSubCategory) {
                const previousSelected = document.getElementById(currentSubCategory);
                if (previousSelected) {
                    previousSelected.classList.remove("selected");
                }
            }

            newSelected.classList.add("selected");

            // Set new sub category
            setCurrentSubCategory(sub_name)
            console.log(main_name)
            console.log(pricing_data[main_name][sub_name])
            setCurrentPricing(pricing_data[main_name][sub_name])
        }
    }

    const initialClick = (name, sub_name) => {
        // Main Category
        var d = document.getElementById(name);
        d.checked = true

        var sub = document.getElementById(name + "_sub");
        sub.style.display = "block";

        // Sub Category
        var newSubSelected = document.getElementById(sub_name);
        newSubSelected.classList.add("selected");

        setCurrentPricing(pricing_data[currentCategory][currentSubCategory])
    }

    useEffect(() => {
        initialClick(currentCategory, currentSubCategory)
    }, []);

    return (
        <section className="pricing-section">
            <div className="pricing-top-container">
                <div className="pricing-top-left-content">
                </div>
                <div className="pricing-top-right-content">
                    <p>Pricing</p>
                </div>
            </div>
            <div className="pricing-content-container">
                <div className="pricing-content-container-left">
                    <div className="pricing-content-container-left-content">
                        <p className="pricing-content-container-left-title">Explore Our Options</p>
                        <p className="pricing-content-container-left-description">We stand out with personalized, high-quality services that go beyond traditional outsourcing.</p>
                        <br/>
                        <a href="/tech-stacks" className="pricing-content-container-tech-stacks-link">Learn more about our tech stacks...</a>
                        <div className="pricing-staff-options-container">
                            <div className="pricing-staff-options-container-background-line"></div>
                            <div className="pricing-radio-input-container">
                                <label class="pricing-radio-input-main" style={{ fontWeight: "bold" }}>
                                    Tech Development
                                    <input id="tech_dev" type="checkbox" name="tech_dev" checked={currentCategory == "tech_dev" ? "checked" : ""} onClick={(e) => didClickCategory(e.target.name)} />
                                    <span class="checkmark"></span>
                                </label>
                                <div id="tech_dev_sub" className="pricing-staff-options-container-mobile-sub" style={{ display: 'none' }}>
                                    <button id="full_stack" name="full_stack" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Full Stack</button>
                                    <button id="front_end" name="front_end" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Front End</button>
                                    <button id="back_end" name="back_end" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Back End</button>
                                    <button id="data_engineer" name="data_engineer" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Data Engineering</button>
                                    <button id="ml_and_lm" name="ml_and_lm" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Machine Learning</button>
                                    <button id="dev_ops" name="dev_ops" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Dev Ops</button>
                                    <button id="mobile" name="mobile" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Mobile</button>
                                    <button id="ui_ux" name="ui_ux" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>UI/UX Design</button>
                                    <button id="graphic" name="graphic" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Graphic Design</button>
                                    <button id="project_manager" name="project_manager" onClick={(e) => didClickSubCategory("tech_dev",e.target.name)}>Project manager/Lead architect</button>
                                </div>
                            </div>

                            <div className="pricing-radio-input-container">
                                <label class="pricing-radio-input-main">
                                    Finance & HR
                                    <input id="finance_hr" type="checkbox" name="finance_hr" checked={currentCategory == "finance_hr" ? "checked" : ""} onClick={(e) => didClickCategory(e.target.name)} />
                                    <span class="checkmark"></span>
                                </label>
                                <div id="finance_hr_sub" className="pricing-staff-options-container-mobile-sub" style={{ display: 'none' }}>
                                    <button id="bookkeepers" name="bookkeepers" onClick={(e) => didClickSubCategory("finance_hr",e.target.name)}>Bookkeepers</button>
                                    <button id="chartered_accountants" name="chartered_accountants" onClick={(e) => didClickSubCategory("finance_hr",e.target.name)}>Chartered Accountants</button>
                                    <button id="cpas" name="cpas" onClick={(e) => didClickSubCategory("finance_hr",e.target.name)}>CPAs</button>
                                    <button id="hr_managers" name="hr_managers" onClick={(e) => didClickSubCategory("finance_hr",e.target.name)}>HR Managers</button>
                                </div>
                            </div>

                            <div className="pricing-radio-input-container">
                                <label class="pricing-radio-input-main">
                                    Business Development
                                    <input id="bus_dev" type="checkbox" name="bus_dev" checked={currentCategory == "bus_dev" ? "checked" : ""} onClick={(e) => didClickCategory(e.target.name)} />
                                    <span class="checkmark"></span>
                                </label>
                                <div id="bus_dev_sub" className="pricing-staff-options-container-mobile-sub" style={{ display: 'none' }}>
                                    <button id="support" name="support" onClick={(e) => didClickSubCategory("bus_dev",e.target.name)}>Customer Support</button>
                                    <button id="marketing" name="marketing" onClick={(e) => didClickSubCategory("bus_dev",e.target.name)}>Marketing</button>
                                    <button id="social_dev" name="social_dev" onClick={(e) => didClickSubCategory("bus_dev",e.target.name)}>Social Media Development</button>
                                    <button id="sales_outreach" name="sales_outreach" onClick={(e) => didClickSubCategory("bus_dev",e.target.name)}>Sales & Outreach</button>
                                </div>
                            </div>

                            <div className="pricing-radio-input-container">
                                <label class="pricing-radio-input-main">
                                    Admin Assistants
                                    <input id="admin_assist" type="checkbox" name="admin_assist" checked={currentCategory == "admin_assist" ? "checked" : ""} onClick={(e) => didClickCategory(e.target.name)} />
                                    <span class="checkmark"></span>
                                </label>
                                <div id="admin_assist_sub" className="pricing-staff-options-container-mobile-sub" style={{ display: 'none' }}>
                                    <button id="exec_assist" name="exec_assist" onClick={(e) => didClickSubCategory("admin_assist",e.target.name)}>Executive Assistants</button>
                                    <button id="admin_support_staff" name="admin_support_staff" onClick={(e) => didClickSubCategory("admin_assist",e.target.name)}>Administrative Support Staff</button>
                                    <button id="legal_assist" name="legal_assist" onClick={(e) => didClickSubCategory("admin_assist",e.target.name)}>Legal Assistants</button>
                                    <button id="personal_assist" name="personal_assist" onClick={(e) => didClickSubCategory("admin_assist",e.target.name)}>Personal Assistants</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing-content-container-right">
                    <div className="pricing-content-recurring-options">
                        <button className={isMonthlyMode ? "selected" : ""} onClick={() => { setMonthly(true) }}>Monthly</button>
                        <button className={isMonthlyMode ? "" : "selected"} onClick={() => { setMonthly(false) }}>Yearly</button>
                    </div>
                    <p className="pricing-content-starting-at-disclaimer">*Prices starting at</p>
                    <div className={`pricing-content-options ${isVisible ? 'visible' : ''}`}>
                        <div className="pricing-content-plan">
                            <h2 className="pricing-content-plan-title">Part Time</h2>
                            <div className="pricing-content-plan-price">
                                <p className="pricing-content-plan-price-label">
                                    {isMonthlyMode ? (
                                        currentPricing.monthly.plan_1.price
                                    ) : (
                                        currentPricing.annual.plan_1.price
                                    )}<span className='asterisk'>*</span>
                                </p>
                                <p className="pricing-content-plan-price-per">
                                    {isMonthlyMode ? "per month" : "per year"}
                                </p>
                            </div>
                            {!isMonthlyMode && (
                                <div className="pricing-content-plan-breakdown">
                                    <p className="pricing-content-plan-price-yearly-month-breakdown">
                                        {currentPricing.annual.plan_1.discount_monthly} per month
                                    </p>
                                </div>
                            )}
                            <button onClick={() => window.open(isMonthlyMode ? (
                                // currentPricing.monthly.plan_1.link
                                "https://buy.stripe.com/9AQbMg0L2bRJgWAg16" // Half time deposit link
                            ) : (
                                // currentPricing.annual.plan_1.link
                                "https://buy.stripe.com/9AQbMg0L2bRJgWAg16" // Half time deposit link
                            ), "_blank")}>Start Now</button>
                            <ul>
                                <li>20 hours per week</li>
                                <li>Dedicated support</li>
                                <li>Insight meetings</li>
                            </ul>
                        </div>
                        <div className="pricing-content-plan">
                            <h2 className="pricing-content-plan-title">Full Time</h2>
                            <div className="pricing-content-plan-price">
                                <p className="pricing-content-plan-price-label">
                                    {isMonthlyMode ? (
                                        currentPricing.monthly.plan_2.price
                                    ) : (
                                        currentPricing.annual.plan_2.price
                                    )}<span className='asterisk'>*</span>
                                </p>
                                <p className="pricing-content-plan-price-per">
                                    {isMonthlyMode ? "per month" : "per year"}
                                </p>
                            </div>
                            {!isMonthlyMode && (
                                <div className="pricing-content-plan-breakdown">
                                    <p className="pricing-content-plan-price-yearly-month-breakdown">
                                        {currentPricing.annual.plan_2.discount_monthly} per month
                                    </p>
                                </div>
                            )}
                            <button onClick={() => window.open(isMonthlyMode ? (
                                // currentPricing.monthly.plan_2.link
                                "https://buy.stripe.com/5kAbMg51i09149O5mt" // Half time deposit link
                            ) : (
                                // currentPricing.annual.plan_2.link
                                "https://buy.stripe.com/5kAbMg51i09149O5mt" // Half time deposit link
                            ), "_blank")}>Start Now</button>

                            <ul>
                                <li>40 hours per week</li>
                                <li>Liaison architect</li>
                                <li>Flexible insight meetings</li>
                                <li>Dedicated support</li>
                                <li>£100 off each additional hire</li>
                                <li>Referral program</li>
                            </ul>
                        </div>
                        <div className="pricing-content-plan">
                            <h2 className="pricing-content-plan-title">Enterprise</h2>
                            <div className="pricing-content-plan-price">
                                <p className="pricing-content-plan-price-label-enterprise">
                                    Contact us for a tailored solution
                                </p>
                            </div>
                            {!isMonthlyMode && (
                                <div className="pricing-content-plan-breakdown">
                                    <p className="pricing-content-plan-price-yearly-month-breakdown">
                                        {currentPricing.annual.plan_3.discount_monthly} per month
                                    </p>
                                </div>
                            )}
                            <button className="enquire" onClick={didClickSchedule}>Enquire now</button>
                            {/* <button className="enquire" onClick={() => window.open(isMonthlyMode ? (
                                currentPricing.monthly.plan_3.link
                            ) : (
                                currentPricing.annual.plan_3.link
                            ), "_blank")}>Enquire now</button> */}
                                <p>Let us meet your enterprise scale needs by arranging a consultation</p>
                                <p>Some of what we offer:</p>
                            <ul>
                                <li>Project based development</li>
                                <li>MVP creation</li>
                                <li>Team assembly</li>
                                <li>Discounts for team size/work duration</li>
                                <li>Management oversight</li>
                                <li>Consulting</li>
                                <li>Candidate contract buyouts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingTwo;